import { createSlice } from '@reduxjs/toolkit';
import Http from '../helpers/http';
import type Pagination from './../models/pagination';
import type { deviceType, deviceOrderType } from '../models/device';
import { toast } from 'react-toastify';

type StatisticsState = {
  device: {
    loading: boolean;
    data?: deviceType;
    error?: string | null;
  };
  deviceList: {
    loading: boolean;
    data: deviceType[];
    error?: string | null;
    meta?: Pagination;
  };
  orderList: {
    loading: boolean;
    data: deviceOrderType[];
    error?: string | null;
    meta?: Pagination;
  };
  order: {
    loading: boolean;
    data?: deviceOrderType;
    error?: string | null;
  };
};

const initialState: StatisticsState = {
  device: {
    loading: false,
  },
  deviceList: {
    loading: false,
    data: [],
  },
  orderList: {
    loading: false,
    data: [],
  },
  order: {
    loading: false,
  },
};

const deviceSlice = createSlice({
  name: 'used-device',
  initialState,
  reducers: {
    fetchUsedDeviceListRequest: (state) => {
      state.deviceList.loading = true;
    },
    fetchUsedDeviceListSuccess: (state, action) => {
      state.deviceList = {
        loading: false,
        data: action.payload.data,
        meta: action.payload.meta,
      };
    },
    fetchUsedDeviceListFailure: (state, action) => {
      state.deviceList.loading = false;
      state.deviceList.error = action.payload;
    },
    fetchUsedDeviceRequest: (state) => {
      state.device.loading = true;
    },
    fetchUsedDeviceSuccess: (state, action) => {
      state.device = {
        loading: false,
        data: action.payload,
      };
    },
    fetchUsedDeviceFailure: (state, action) => {
      state.device.loading = false;
      state.device.error = action.payload;
    },
    //PRODUCT DELETE
    deleteProductSuccess: (state, action) => {
      state.deviceList.data = state.deviceList.data?.filter(
        (prod) => prod.id !== action.payload
      );
    },
    fetchDeviceOrderListRequest: (state) => {
      state.orderList.loading = true;
    },
    fetchDeviceOrderListSuccess: (state, action) => {
      state.orderList = {
        loading: false,
        data: action.payload.data,
        meta: action.payload.meta,
      };
    },
    fetchDeviceOrderListFailure: (state, action) => {
      state.orderList.loading = false;
      state.orderList.error = action.payload;
    },
    fetchDeviceOrderRequest: (state) => {
      state.order.loading = true;
    },
    fetchDeviceOrderSuccess: (state, action) => {
      state.order = {
        loading: false,
        data: action.payload,
      };
    },
    fetchDeviceOrderFailure: (state, action) => {
      state.order.loading = false;
      state.order.error = action.payload;
    },
  },
});

export default deviceSlice.reducer;
export const deviceActions = deviceSlice.actions;

export const createUsedProductAction =
  (data: unknown) => async (dispatch: any) => {
    try {
      const res = await Http.Post({
        path: `used_products`,
        data,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      return result;
    } catch (error: any) {
      console.error(error.message);
    }
  };
export const updateUsedProductAction =
  (id: number, data: unknown) => async (dispatch: any) => {
    try {
      const res = await Http.Put({
        path: `used_products/${id}`,
        data,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      return result;
    } catch (error: any) {
      console.error(error.message);
    }
  };
export const fetchUsedDeviceAction = (id: number) => async (dispatch: any) => {
  dispatch(deviceActions.fetchUsedDeviceRequest());
  try {
    const res = await Http.Get({
      path: `used_products/${id}`,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }
    dispatch(deviceActions.fetchUsedDeviceSuccess(result));
  } catch (error: any) {
    dispatch(deviceActions.fetchUsedDeviceFailure(error.message));
  }
};
export const fetchUsedDeviceListAction =
  (page = 1, pageSize = 20) =>
  async (dispatch: any) => {
    dispatch(deviceActions.fetchUsedDeviceListRequest());
    try {
      const res = await Http.Get({
        path: `used_products?page=${page}&limit=${pageSize}`,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      dispatch(deviceActions.fetchUsedDeviceListSuccess(result));
    } catch (error: any) {
      dispatch(deviceActions.fetchUsedDeviceListFailure(error.message));
    }
  };

export const deleteUsedDeviceAction =
  (productId: number) => async (dispatch: any) => {
    try {
      const response = await Http.Delete({
        path: `used_products/${productId}`,
        useAuth: true,
      });
      if (!response.ok) {
        throw new Error('Something wrong');
      }
      toast.success('Deleted');
      dispatch(deviceActions.deleteProductSuccess(productId));
    } catch (error: any) {
      toast.error(error.message);
    }
  };

export const fetchOrderDeviceListAction =
  (page = 1, pageSize = 20) =>
  async (dispatch: any) => {
    dispatch(deviceActions.fetchDeviceOrderListRequest());
    try {
      const res = await Http.Get({
        path: `used-order?page=${page}&limit=${pageSize}`,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      dispatch(deviceActions.fetchDeviceOrderListSuccess(result));
    } catch (error: any) {
      dispatch(deviceActions.fetchDeviceOrderListFailure(error.message));
    }
  };
export const fetchOrderDeviceAction = (id: number) => async (dispatch: any) => {
  dispatch(deviceActions.fetchDeviceOrderRequest());
  try {
    const res = await Http.Get({
      path: `used-order/${id}`,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed');
    }
    dispatch(deviceActions.fetchDeviceOrderSuccess(result));
  } catch (error: any) {
    dispatch(deviceActions.fetchDeviceOrderFailure(error.message));
  }
};
export const updateOrderDeviceListAction =
  (orderId: number, data: any) => async (dispatch: any) => {
    dispatch(deviceActions.fetchUsedDeviceListRequest());
    try {
      const res = await Http.Put({
        path: `used_products/${orderId}`,
        data,
        useAuth: true,
      });
      const result = await res.json();
      if (!res.ok) {
        throw new Error('Failed');
      }
      return result;
    } catch (error: any) {
      dispatch(deviceActions.fetchUsedDeviceListFailure(error.message));
    }
  };
